import { LitElement } from 'lit';
import { customElement } from 'lit/decorators.js';

if ('serviceWorker' in navigator) {
  const baseElement = document.querySelector('base') as HTMLBaseElement

  if (baseElement) {
    const index = baseElement.href.indexOf(document.location.host);;
    let baseAddress = baseElement.href.substring(index + document.location.host.length)

    if (!baseAddress.endsWith('/')) {
      baseAddress += '/';
    }

    //navigator.serviceWorker.register(baseAddress + 'sw.js')
    //  .catch(reason => {
    //    // ignore
    //  });
  }
}

interface WindowProxy {
  verifyPWAInstall: () => void;
  configurePWAInstallButton: () => void;
  executePWAInstall: () => void;
  openInNewWindow: (url: string) => void;
  openReport: (url: string) => void;
  isDevice: () => boolean
  getUrlPdf: (url: string) => string;
  downloadFile: (fileName: string, data: string) => void;
  copyClipboard: (content: string) => void;
}

const windowProxy = (window as unknown) as WindowProxy;

let pwaInstallVerified = false;

windowProxy.configurePWAInstallButton = function () {
  const installButton = document.querySelector('#install-button') as PWAInstallElement;
  const pwaInstall = document.querySelector('pwa-install') as PWAInstallElement;

  if (pwaInstall && installButton && pwaInstall.isInstallAvailable) {
    installButton.style.display = 'block';
  }
}

windowProxy.verifyPWAInstall = function () {
  if (!pwaInstallVerified) {
    const pwaInstall = document.querySelector('pwa-install') as PWAInstallElement;

    if (pwaInstall && pwaInstall.isInstallAvailable) {
      pwaInstall.showDialog();
    }
  }

  pwaInstallVerified = true;
}

windowProxy.executePWAInstall = function () {
  const pwaInstall = document.querySelector('pwa-install') as PWAInstallElement;

  if (pwaInstall && pwaInstall.isInstallAvailable) {
    pwaInstall.showDialog();
  }
}

windowProxy.openInNewWindow = function (url)  {
    const newTab = window.open('', '_blank');
    newTab!.location.href = url.replaceAll('\"', '');
    window.close();
}

windowProxy.openReport = (data) => {
    const file = new Blob([data], { type: 'application/pdf' });
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL);
}

windowProxy.getUrlPdf = (data) => {
    const file = new Blob([data], { type: 'application/pdf' });
    return URL.createObjectURL(file);
}

windowProxy.copyClipboard = (content) => {
    navigator.clipboard.writeText(content);
}

windowProxy.downloadFile = async (fileName, data) => {
    const blob = new Blob([data]);
    const url = URL.createObjectURL(blob);
    const anchorElement = document.createElement('a');
    anchorElement.href = url;
    anchorElement.download = fileName || '';
    anchorElement.click();
    anchorElement.remove();
    URL.revokeObjectURL(url);
}

windowProxy.isDevice = () => {
    return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
}

interface PWAInstallElement extends HTMLElement {
  showDialog(): void;
  isInstallAvailable: boolean;
}

@customElement('wgcommerce-app')
export class WGCommerceElement extends LitElement {
  public connectedCallback() {
    super.connectedCallback();
  }

  public render() {
    return '';
  }
}
